// -- elements -- //
////////////////////

#toTopBtn {
	display: none; 
	position: fixed; 
	bottom: 30px; 
	right: 20px; 
	z-index: 99; 
	border: none; 
	outline: none; 
	background-color: $primary; 
	color: white; 
	cursor: pointer; 
	padding: 15px; 
	border-radius: $border-radius; 
	font-size: 18px;
	box-shadow: 0px 0px 5px $primary;
}

//images
@import "./_images.scss";

//autocomplete styles
@import "./_autocomplete.scss";

//calendar
@import "./_calendar.scss";

//bussiness card
@import "./_bussinesscard.scss";

//news 
@import "./_news.scss";

//form styles
@import "./_forms.scss";

//banners
@import "./_banners.scss";