/* --- bussiness card --- */

.bc-wrapper{
	text-align: center;
}

.business-card {
	position: relative;
	display: block;
	margin-bottom: 1.5em;
	//padding: .5em .5em .5em 20px;
	font-size: $font-size-bussinesscard;
	text-align: left;
	font-family: $font-family-content;
	font-weight: 300;

	@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
		padding: 30px;
	}
}

.subscribe-wrapper {
	@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
		padding: 30px;
	}
}

p.email a {
	
	&:link, &:visited, &:hover, &:active {
		color: $color-base-black;
		text-decoration: underline;
	}
}

.business-card {

	p {
		margin-bottom: 7px;
		&.org {
			font-weight: bold;
			font-family: $font-family-headers-light;
		}

		&.country-name {
			margin-top: 20px;
			font-weight: bold;
			font-family: $font-family-headers-light;
		}
		&.email {
			margin-top: 20px;
		}
	}
}