
footer {
    margin-top: 5px;
    padding-top: 60px;
    background-color: $yellow-bg;
}

p#colophon{
    padding-top:10px;
    padding-bottom:10px;
    text-align: left;
    background-color: $yellow-link;
    margin-bottom: 0;
    padding-left: 30px;
    a {
        &:link, &:visited, &:hover, &:active {
            color: $color-base-black;
            text-decoration: none;
            text-transform: lowercase;
        }
    }
    }
    