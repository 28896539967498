/* --- Entry --- */
	
body#body-entry {
    .training {
		.training-wrapper {
			.training-inner {
				position: relative;
				margin-bottom: 15px;

				.background-image {
					height: 645px;
					background-position: center;
					background-repeat: no-repeat;
					background-size: cover;

					@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
						height: 400px;
					}
				}

				.training-text {
					position: absolute;
					background-color: rgba($grey-bg-lighter,.55);
					bottom: 0;
					padding: 30px 30px 40px 30px;
					text-rendering: optimizeLegibility;

					.training-description {
						text-rendering: optimizeLegibility;
						transform: translateY(6px);
					}

					.training-title {
						transform: translateY(6px);
					} 
					
					@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
						position: relative;
					}					  
				}
			}

			.owl-dots {
				bottom: 0;
				transform: translateY(-235px);
				text-align: left;
				margin-left: 30px;

				.owl-dot {
					span {
						border-radius: 0;
						width: 12px;
						height: 12px;
						margin: 0 8px 0 0;						
					}

					&.active {
						span {
							background: $grey-link
						}
					}
				}
			}
		}

	}

	.about {
		.about-inner {
			margin-top: 60px;
			padding: 23px 20px 40px 30px; 
			background-color: $yellow-bg;
			letter-spacing: 0px;

			@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
				margin-top: 0;
			}			
		}

		@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
			margin-bottom: 15px;
		}
	}

	.news-main {
		margin-top: 16px;
		
		.news-list {
			&.wrapper {
				.news-item {
					background-color: $grey-bg;
					width: calc(25% - 7.5px);
					margin-bottom: 30px;

					@media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
						width: calc(33.33333% - 6.6px);
					}
					@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
						width: calc(50% - 5px);
					}

					@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
						width: 100%;
					}

					.background-image {
						height: 210px;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;						
					}

					&:after {
						content: '';
						display: block;
						clear: both;
					}

					.text-wrapper {
						padding: 37px 30px 40px 30px;

						.category {
							color: $red-title;
							text-transform: uppercase;
							font-family: $font-family-headers;
							margin-bottom: 5px;
						}

						.date {
							margin-bottom: 10px;
						}
					}
				}

	
			}
		}
	}

}