h1 {
    margin-bottom: .5em; //relative to current font size if rem then it would be base*50% always
    font-family: $font-family-headers-light;
    font-size: $font-size-heading-1;
    text-transform: uppercase;
    margin-bottom: .6rem;

    &.maintitle{
        font-family: $font-family-headers-extralight;
        font-size: 4.375rem;
        color: $grey-bg;
        margin-bottom: -20px;
        margin-left: -6px;
    } 
}
h2 {
    margin-bottom: .1rem;
    font-family: $font-family-headers-light;
    font-size: $font-size-heading-2;
    text-transform: uppercase;
}
h3{
    margin-bottom: .1rem;
    font-family: $font-family-headers-light;
    font-size: $font-size-heading-3;
    text-transform: uppercase;
}

#gallery-container {
    overflow: hidden;

    .gallery-container-row {
        margin-right: -5px;
        margin-left: -5px;
    }
    .g-item {       
        flex-wrap: wrap;
        a {
            display: block;
            width: 100%;
        }

        &#gallery-item-1,&#gallery-item-2,&#gallery-item-4, &#gallery-item-6 {
            flex-grow:2;
        }

        &#gallery-item-3 {
            flex-grow:1;
        }
        &#gallery-item-5 {
            flex-grow:3;
        }        
        .background-image {
            //border: 5px solid $color-base-white;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            flex-basis: 100%;
            box-shadow:inset 0px 0px 0px 5px $color-base-white;
            &:after{
                content: '';
                display: block;
                padding-bottom: 100%;
              }
        }

        &#gallery-item-1, &#gallery-item-6 {
            :first-child, :nth-child(2) {
                flex-basis: 50%;
            }
            :nth-child(3) {
                flex-basis: 100%;
            }            
        }

        &#gallery-item-2, &#gallery-item-4 {
            :nth-child(2), :nth-child(3) {
                flex-basis: 50%;
            }

            :first-child {
                flex-basis: 100%; 
            }
        } 
        
        &#gallery-item-3 {
            .background-image {
                flex-basis: 100%;
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'xxl')) {
        .gallery-container-row {
            margin-right: -2px;
            margin-left: -2px;
               
            .g-item { 
                .background-image {
                    box-shadow:inset 0px 0px 0px 2px $color-base-white;
                } 
            }
        }
    }
}

.about-row {
    .grey-bg-lighter {
        h2 {
            margin-bottom: 15px;
        }
    }
}

.sklopi {
    margin-top: 70px;
    .training-wrapper-about {
        .tabout-inner {
            @media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
                min-width: 33.3333%;
                flex-grow: 0;
                margin-bottom: 30px;
            }
            @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
                min-width: 50%;
            }        
            .tabout-inner-rel {
                position: relative;
                .training-text {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    max-width: 100%;
                    transform: translate(-50%,-50%);
                    padding: 2px 7px 0px 7px;
                    a {
                        &:link,
                        &:visited,
                        &:active,
                        &:visited {
                            color: $color-base-black;
                            text-decoration: none;
                            background-color: transparent;
                            outline: none;
                        }                
                    }                
                }
                .background-image {
                    height:130px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;  
                    filter: sepia(1) opacity(.3);  

                }

                &:hover {
                    .background-image {
                        filter: sepia(.5) opacity(1);
                    }

                    .training-text { 
                        background-color: $grey-bg-lighter;
                    }
                }
            }

        }
    }
    
}

.cilji {
    margin-top: 70px;

    ul {
        padding-left:0;

        li {
            list-style-type: none;

            &:before {
                content: '■';
                font-size: 1.5rem;
                color: rgba($red-title,.4);
                margin-right: 7px;
            }
        }
    }
}

.ekipa {
    margin-top: 70px;
    h2 {
        text-transform: none;
    }

    .photo-list-item {
        flex-basis: 50% !important;
        
    }
    .photo-list-photo {
        flex-basis: 60%;
        height:208px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; 
    }

    .photo-list-content {
        flex-basis: 40%;
        background-color: $grey-bg-lighter !important;
    }

    #back-to-list {
        display: none;
    }
}

.mentorji {
    margin-top: 70px;
    h2 {
        text-transform: none;
    }
    .list {
        flex-wrap:wrap;

        .ment-item {
            flex-basis: 33.33333%;
            margin-bottom: 15px;
        }
    }

    #back-to-list {
        display: none;
    }
    
    .padding-30 {
        padding: 30px 30px 0px 30px;
    }
}

.training-title-side {
    margin-top: 5px;

    &.main {
        margin-top: 0px;
    }
    a {
        &:link,
        &:visited,
        &:active,
        &:visited {
            color: $color-base-black;
            text-decoration: none;
            background-color: transparent;
            outline: none; 
            
        } 
    }
}

.usposabljanja {
    .training-lead {
        height: 240px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;         
    }

    .fa-angle-right {
        font-size: 27px;
        transform: translateY(1px); 
        margin-left: 5px; 
    }

    .about-inner {
        padding-bottom: 0 !important;
    }

    .other {
        padding-bottom: 30px;
    }

    .wrap {
        width: calc(100% + 50px);
        margin-left:-30px;

        &.bottom {
            width: 100%;
            margin-left:0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 10px;
            .photo-gallery-item {
                flex-basis: 49%;
                margin-bottom: 10px;
            } 
        }

        &.slick-vertical {
            background-color: $color-base-white;
            .slick-slide {
                border: none;
            }
        }

        .background-slick {
            height: 220px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;         
        }        
    }

}

blockquote {
    &.ulb {
        border-left: none;
        padding: 0;
        margin: 0;
        font-size: $font-size-content;
        margin-top: 30px;
        font-style: normal !important;
        ul {
            
            li {
               list-style-type: none;
               margin-bottom: 5px;

               a {
                &:link,
                &:visited,
                &:active,
                &:visited {
                    color: $color-base-black;
                    text-decoration: none;
                    background-color: transparent;
                    outline: none; 
                    
                } 
               }


               &:before {
                content: "\f0c8";
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 10px;
                text-rendering: auto;
                margin-right: 15px;  
                vertical-align: middle;  
                color: rgba($red-title,1);
                padding-bottom: 5px;
               }
            }
        } 
    }
   
}

.grecaptcha-badge {
    bottom: 34px !important;
}

#cookie-button {
    padding: 6px 15px !important;
    border-radius: 0 !important;
}