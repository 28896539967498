/* Print styles
-------------------------------------*/

body {
	font-size: .9em;
	font-family: $font-family-print;
	background-color: white;
	color: black;
}

h1, h2, h3, h4 {
	font-family: $font-family-print;
}

h1 {
	font-size: 1.6em;
}

h2 {
	font-size: 1.4em;
}

h3 {
	font-size: 1.2em;
}

h4 {
	font-size: 1.1em;
}

a img {
	border: none;
}

article a {
	&:link:after, &:visited:after {
		content: " (" attr(href) ") ";
		font-size: 90%;
	}
}

.hb-inner img {
	max-width: 100px;
}

div {
	flex: none !important;
}

.header-wrapper-all, .row, .header-wrapper, .training, .d-flex {
	display: block !important;
}


.logo-anchor2, .owl-dots, .subscribe-wrapper, .more-link,.banners, #colophon, #cookie-button, #cookieDiv, #loadMore {
	display: none !important;
}

#body-content {
	.sklopi, .cilji, .right-title, .other {
		display: none !important;
	}

	.grey-bg-lighter {
		background-color: transparent;
	}

	.padding-30 {
		padding: 30px 0;
	}

	.text-center {
		text-align: left !important;
	}
}

.about {
	width: 100%;
	max-width: 100%;
	.about-inner {
		padding: 30px 0;
	}
}

.col-xs-12, .col-lg-8, .col-xl-9, .col-sm-12, .col-md-4 {
	width: 100%;
	max-width: 100%;
}

.offset-md-2 {
	margin-left: 0;
}

.news-item {
	position: relative;
	width: 100%;
}

.bc-wrapper {
	border-top: 1px solid black;
	margin-top: 30px;
	.business-card {
		font-size: 14px;
		margin-top: 30px;
		p {
			margin-bottom: 0;
		}
	}
}

.news-item {
	display: block !important;
}

.photo-gallery-item {
	position: relative !important;
	display: none !important;
	.img-fluid.photo-gallery {
		max-height: 200px;
	}
}

.img-fluid.media-object {
	max-width: 200px;
}

.banner.img-fluid {
	max-width: 100px;
}

blockquote {
	border: none;
}

