/* --- Autocomplete --- */

.ac_results {
	padding: 0px;
	background-color: white;
	overflow: hidden;
	z-index: 99999;

	ul {
		width: 100%;
		list-style-position: outside;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	li {
		margin: 0px;
		padding: 5px 10px;
		cursor: default;
		display: block;
		cursor: pointer;
		transition: all 150ms ease-in-out;

		/* 
		if width will be 100% horizontal scrollbar will apear 
		when scroll mode will be used
		*/

		/*width: 100%;*/
		font-family: $font-family-content;
		font-size: 0.85174rem; // 12px/14px

		/* 
		it is very important, if line-height not setted or setted 
		in relative units scroll will be broken in firefox
		*/
		
		line-height: 1;
		overflow: hidden;
	}
}

.ac_odd {
	background-color: #F6F6F6;
}

.ac_over {
	background-color: #606060;
	color: white;
}