@font-face {
	font-family: 'RalewayBlack';
	src: url('../../fonts/raleway/RALEWAY-BLACK.TTF');
	color: black;
}
@font-face {
	font-family: 'RalewayBlackItalic';
	src: url('../../fonts/raleway/RALEWAY-BLACKITALIC.TTF');
	color: black;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayBoldItalic';
	src: url('../../fonts/raleway/RALEWAY-BOLDITALIC.TTF');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayExtraBold';
	src: url('../../fonts/raleway/RALEWAY-EXTRABOLD.TTF');
	font-weight: 800;
}
@font-face {
	font-family: 'RalewayExtraBoldItalic';
	src: url('../../fonts/raleway/RALEWAY-EXTRABOLDITALIC.TTF');
	font-weight: 800;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayExtraLight';
	src: url('../../fonts/raleway/RALEWAY-EXTRALIGHT.TTF');
	font-weight: 200;
}
@font-face {
	font-family: 'RalewayExtraLightItalic';
	src: url('../../fonts/raleway/RALEWAY-EXTRALIGHTITALIC.TTF');
	font-weight: 200;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayItalic';
	src: url('../../fonts/raleway/RALEWAY-ITALIC.TTF');
	font-style: italic;
}
@font-face {
	font-family: 'RalewayLight';
	src: url('../../fonts/raleway/RALEWAY-LIGHT.TTF');
	font-weight: 300;
}
@font-face {
	font-family: 'RalewayLightItalic';
	src: url('../../fonts/raleway/RALEWAY-LIGHTITALIC.TTF');
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayMedium';
	src: url('../../fonts/raleway/RALEWAY-MEDIUM.TTF');
	font-weight: 500;
}
@font-face {
	font-family: 'RalewayMediumItalic';
	src: url('../../fonts/raleway/RALEWAY-MEDIUMITALIC.TTF');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayRegular';
	src: url('../../fonts/raleway/RALEWAY-REGULAR.TTF');
	font-weight: 400;
}
@font-face {
	font-family: 'RalewaySemibold';
	src: url('../../fonts/raleway/RALEWAY-SEMIBOLD.TTF');
	font-weight: 600;
}
@font-face {
	font-family: 'RalewaySemiboldItalic';
	src: url('../../fonts/raleway/RALEWAY-SEMIBOLDITALIC.TTF');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'RalewayThin';
	src: url('../../fonts/raleway/RALEWAY-THIN.TTF');
	font-weight: 100;
}
@font-face {
	font-family: 'RalewayThinItalic';
	src: url('../../fonts/raleway/RALEWAY-THINITALIC.TTF');
	font-weight: 100;
	font-style: italic;
}