/* Sidebar
-------------------------------------*/

.sidebar-module {
	margin-bottom: 4em;

	h2 {
		position: relative;
		margin-bottom: .7em;
		font-family: $font-family-headers;
		font-size: $font-size-sidebar-headers;
		color: #666;
	}
}


/* --- faq --- */

#faq-form-toggle {
	text-decoration: underline;
	cursor: pointer;
}

.faq-list {
	display: none;
	clear: left;

	dt {
		font-weight: bold;
		margin-bottom: .5em;
	}

	dd {
		margin-bottom: 1em;
	}
}

#faq-list h2 {
	float: left;
	cursor: pointer;
	padding-right: 18px;
}

#faq-form {
	input.input-text, textarea, select {
		width: 180px;
	}

	label {
		display: block;
		margin-top: .5em;
		font-weight: bold;
	}

	.mandatory {
		//color: #B90000;
		font-family: $font-family-sans-serif;
		font-weight: normal;
	}
}

#faq {
	a {
		&:link, &:visited, &:hover, &:active {}
	}

	.form-error, .racun {
		font-size: 1.15em;
	}
}


/* --- poll --- */

#poll-question {
	margin-bottom: .5em;
	font-weight: bold;
	font-size: 1.15rem;
}

#poll-container a {
	display: block;
	margin-top: 1em;
}

#poll-answers-area {
	font-size: 1.15rem;

	.poll-pix {
		float: left;
		background-color: black;

		/* poll results line color */
	}

	.poll-percent {
		margin-left: 3px;
		float: left;
	}

	.poll-answer, .poll-votes-total {
		padding-top: .5em;
		clear: left;
	}
}

#poll {
	a {
		&:link, &:visited, &:hover, &:active {}
	}

	.poll-archive, label {
		font-size: 1.15em;
	}
}
