/* Shop
-------------------------*/

#head {
	#product-image {
		float: left;
		min-height: 103px;
		width: 160px;
	}

	#product-title {
		float: left;
		min-height: 103px;
		width: 70%;
		margin-left: 10px;
	}

	p {
		padding-left: 0;
		float: left;
	}	
}

#description {
	clear: both;
}

span#description p {
	font-size: 1rem;
	padding: 0;
}

#product-list {
	li span.label {
		background-color: #666666;
		color: white;
		padding: 0 1px 0 2px;
		margin-right: 1px;
	}
	padding-top: 5px;
	width: 100%;
	padding-bottom: 4px;
}

#text-content-container #product-list li {
	margin-bottom: 12px;
	padding-bottom: 12px;
	list-style-type: none;
	border-bottom: 1px dotted #BCBCBC;
}

#product-list {
	li {
		margin-bottom: 12px;
		padding-bottom: 12px;
		list-style-type: none;
		border-bottom: 1px dotted #BCBCBC;
	}

	.product-image {
		min-height: 103px;
		float: left;
	}
}

#module-new .product-image {
	min-height: 103px;
	float: left;
}

#product-list .product-title, #module-new .product-title {
	float: left;
	padding: 0px 18px 36px 13px;
	position: relative;
	min-height: 67px;
}

#product-list .product-title {
	padding-left: 15px;
}

#text-content-container #product-list .product-title {
	width: 180px;
	border-right: 1px dotted #9D9D9D;
}

#product-list .product-title a.more {
	&:link, &:visited, &:hover, &:active {
		position: absolute;
		bottom: 15px;
		left: 13px;
		color: #DADADA;
		padding-right: 14px;
		font-size: 1rem;
	}
}

#module-new .product-title a.more {
	&:link, &:visited, &:hover, &:active {
		position: absolute;
		bottom: 15px;
		left: 13px;
		color: #DADADA;
		padding-right: 14px;
		font-size: 1rem;
	}
}

#product-list .product-title a.more {
	&:link, &:visited, &:hover, &:active {
		left: 15px;
		color: #333333;
	}

	&:hover {
		text-decoration: none;
	}
}

#module-new .product-title {
	a.more:hover {
		text-decoration: none;
	}

	padding-right: 0;
	width: 321px;
	float: left;

	p {
		padding-top: 5px;
		font-size: 1rem;
		color: #DADADA;
	}
}

.product-title h3 {
	padding-top: 13px;
	font-size: 1.6rem;
	padding-bottom: 5px;

	a {
		&:link, &:visited, &:hover, &:active {
			color: #333333;
			text-decoration: none;
		}
	}
}

#text-content-container .product-title h3 a {
	&:link, &:visited, &:hover, &:active {
		color: #333333;
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
	}
}


.product-price {
	min-height: 90px;
	text-align: center;
	padding-top: 13px;

	span {
		color: #333333;
		font-weight: bold;
		// display: block;
		font-size: 1rem;
		padding-left: 5px;
	}

	input {
		display: inline-block;
		border: none;
		text-align: center;
		width: 40px;
		color: black;
		background: none;
		margin-left: 1px;
		border: 1px solid #AAAAAA;
	}
}

#text-content-container #product-info input {
	border: none;
	text-align: center;
	width: 15px;
	color: black;
	background: none;
	margin-left: 1px;
	border: 1px solid #AAAAAA;
}
#product-info{
	form{
		margin-bottom: 10px;
		.btnInput{
			width: 40px;
			text-align: center;
		}
	}
}

.product-price p {
	float: left;
	width: 100%;
	background: white;
	color: #717171;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	padding: 3px 0px 3px 2px;
	position: relative;
	margin: 12px 0px 12px 5px;
}

#text-content-container {
	.product-price p {
		float: left;
		width: 100%;
		background: white;
		color: #717171;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		padding: 3px 0px 3px 2px;
		position: relative;
		margin: 12px 0px 12px 5px;
	}

	#product-info p {
		float: left;
		width: 100%;
		background: white;
		color: #717171;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		padding: 3px 0px 3px 2px;
		position: relative;
		margin: 12px 0px 12px 5px;
		position: absolute;
		left: 193px;
		bottom: 15px;
		margin: 0;
		text-align: center;
	}

	.product-price form {
		float: left;
		width: 100%;
		text-align: center;
		margin: 0;
		padding: 0;
		background: none;
	}
}

a.add-to-basket {
	color: $color-base-text !important;
	&:link, &:visited, &:hover, &:active {
		
		// background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 72px 3px #606060;
		// clear: both;
		// color: #FFFFFF;
		// float: left;
		// font-size: 1em;
		// font-weight: bold;
		// line-height: 1.2em;
		// margin-left: 5px;
		// padding: 3px 30px 3px 5px;
		text-decoration: none !important;
	}
}


#text-content-container .product-price a.add-to-basket {
	&:link, &:visited, &:hover, &:active {
		background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 72px 3px #606060;
		clear: both;
		color: #FFFFFF;
		float: left;
		font-size: 1rem;
		font-weight: bold;
		line-height: 1.2;
		margin-left: 5px;
		padding: 3px 30px 3px 5px;
		text-decoration: none;
	}
}

.product-price a.add-to-basket-ita {
	&:link, &:visited, &:hover, &:active {
		background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 86px 2px #F0A32A;
		clear: both;
		color: #FFFFFF;
		float: left;
		font-size: 1em;
		font-weight: bold;
		line-height: 1.2em;
		margin-left: 5px;
		padding: 3px 30px 3px 5px;
		text-decoration: none;
		width: 72px;
	}
}

#text-content-container .product-price a {
	&.add-to-basket-ita {
		&:link, &:visited, &:hover, &:active {
			background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 86px 2px #F0A32A;
			clear: both;
			color: #FFFFFF;
			float: left;
			font-size: 1em;
			font-weight: bold;
			line-height: 1.2em;
			margin-left: 5px;
			padding: 3px 30px 3px 5px;
			text-decoration: none;
			width: 72px;
		}
	}

	&.add-to-basket-slo:hover, &.add-to-basket-ita:hover {
		text-decoration: none;
	}
}

#module-products a#more-products {
	&:link, &:visited, &:hover, &:active {
		color: #DADADA;
		padding-right: 15px;
	}

	&:hover {
		text-decoration: none;
	}
}

// #module-shopping-cart, #module-shopping-cart-center {
// 	float: left;
// 	width: 100%;
// 	margin: 12px 0px 11px 0px;
// }

// #module-shopping-cart li, #module-shopping-cart-center li {
// 	float: left;
// 	width: 214px;
// 	padding: 5px 8px 4px 10px;
// 	border-radius: 5px;
// 	-webkit-border-radius: 5px;
// 	background: #dadada;
// 	font-size: 0.9em;
// 	margin-bottom: 3px;
// }

#text-content-container ul#module-shopping-cart-center li {
	list-style-type: none;
}

// #module-shopping-cart li.even {
// 	background: #DADADA;
// 	padding: 4px 8px 4px 10px;
// }

// #module-shopping-cart-center li.even {
// 	background: #3e3e3e;
// 	padding: 4px 8px 4px 10px;
// }

// #module-shopping-cart li.odd {
// 	background: #DADADA;
// 	padding: 4px 8px 4px 10px;
// }

// #module-shopping-cart-center li.odd {
// 	background: #3e3e3e;
// 	padding: 4px 8px 4px 10px;
// }


#text-content-container #cart #cart-wrapper #module-shopping-cart {
	ul#module-shopping-cart {
		list-style-type: none;
		width: 60%;
	}

	#cart-total {
		border-top: 1px solid #F0A32A;
		margin-left: 37px;

		span.suma {
			padding-left: 10px;
			width: auto;
		}
		span.total-price{
			width: auto;
			float:right;
		}
	}
}



#module-shopping-cart .cart-title, #cart-total .suma, #module-shopping-cart-center .cart-title, #cart-total-center .suma {
	width: 127px;
	display: block;
	float: left;
}

// #module-shopping-cart .cart-price, #cart-total .total-price, #module-shopping-cart-center .cart-price, #cart-total-center .total-price {
// 	float: left;
// 	display: block;
// 	width: 50px;
// 	text-align: right;
// }

#module-shopping-cart a.cart-delete {
	&:link, &:visited, &:hover, &:active {
		display: block;
		text-indent: -9999px;
		overflow: hidden;
		background: url("../images/backgrounds/bgnd-cart-delete.gif") left top no-repeat;
		float: right;
		width: 13px;
		display: block;
		height: 14px;
	}
}

#cartUpdate a.cart-delete {
	&:link, &:visited, &:hover, &:active {
		display: block;
		text-indent: -9999px;
		overflow: hidden;
		background: url("../images/backgrounds/bgnd-cart-delete.gif") left top no-repeat;
		float: right;
		width: 13px;
		display: block;
		height: 14px;
	}
}

#module-shopping-cart a.cart-delete:hover {
	background-position: left bottom;
}

#cart a#pay {
	&:link, &:visited, &:hover, &:active {
		display: block;
		float: left;
		padding: 3px 26px 6px 14px;
		color: black;
		text-decoration: none;
		position: relative;
		left: 10px;
		border-radius: 5px 0px 0px 5px;
		-webkit-border-radius: 5px 0px 0px 5px;
	}

	&:hover {
		background-position: right bottom;
		color: white;
	}
}

#text-content-container span {
	&.cart-progress {
		/*display: block;*/
		border: 1px solid white;
		padding: 5px;
		color: white;
	}

	&.cart-selected {
		background-color: white;
		padding: 5px;
		color: #292929;
	}
}

#module-shopping-cart {
	padding-bottom: 12px;
	margin-bottom: 12px;

	a#pay {
		color: white;
		display: inline-block;
		padding: .3em 2em .3em 1em;
	}
}

#text-content-container {
	#product-title {
		span {
			background-color: #66656A;
			color: white;
			font-size: 0.8rem;
			line-height: 2.2em;
			margin-right: 5px;
			padding: 3px 0 3px 5px;
		}

		form p span {
			background-color: #CBCACC;
			color: #333333;
			font-size: 1rem;
		}
	}

	.item-price {
		display: block;
		width: 100%;
	}

	#product-title {
		p.item-price span {
			&.item-price-label {
				padding: 3px 5px 3px 5px;
				background-color: #F0A32A;
				color: white;
			}

			&.item-price-label-2 {
				padding: 3px 5px 3px 5px;
				background-color: #B00101;
				color: white;
			}
		}

		form {
			a {
				&.add-to-basket-slo {
					background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 72px 3px #F0A32A;
					font-size: 0.8rem;
					color: #fff;
					font-weight: bold;
					float: right;
					line-height: 1.2em;
					margin-left: 13px;
					margin-top: 7px;
					padding: 3px 22px 3px 5px;
					text-decoration: none;
				}

				&.add-to-basket-ita {
					background: url("../images/backgrounds/bgnd-cart-link.png") no-repeat scroll 86px 2px #F0A32A;
					font-size: 0.8rem;
					color: #fff;
					font-weight: bold;
					float: right;
					line-height: 1.2em;
					margin-left: 13px;
					margin-top: 7px;
					padding: 3px 22px 3px 5px;
					text-decoration: none;
					width: 80px;
				}
			}

			p.item-quantity {
				float: left;
				width: 110px;
				background: white;
				color: #717171;

				/*border-radius: 5px;
				-webkit-border-radius: 5px;*/
				padding: 3px 0px 3px 2px;
				position: relative;
				margin: 12px 0px 12px 0px;
				font-size: 90%;

				input.btnInput {
					border: none;
					width: 70px;
				}
			}
		}
	}

	#product-list li h2 {
		padding-top: 35px;
		font-size: 1.6rem;
	}
}

#cartUpdate table {
	width: 100%;

	tr {
		&.alt td {
			background: #DADADA;
		}

		&.sum td {
			font-weight: bold;
		}
	}
}

#shop-link-register, #shop-link-forgetpass {
	margin-right: 5px;
}

/* Shopping Cart 
--------------------------------*/

#module-shopping-cart{
	
	ul{
		li{
			position:relative;
			border:none;
			border-top: 1px solid #ddd;
			&:first-child{
				border:none;
				border-radius: 0px;
			}
			&:last-child{
				border-radius: 0px;
			}
			&.list-group-item{
				padding: 10px 25px 10px 15px;
				&.total{
					
				}
			}
			a.imgCartEdit, span#shopping-cart-icon{
				position: absolute;
				top:10px;
				right:10px;
			}
			a.imgCartEdit{
				color: $color-base-black;
			}
			
		}
	}
	#cart-total{
		float: left;
		width: 220px;
		padding: 10px 15px;
		font-size: $font-size-content-plus;

		margin-bottom: 24px;
		border-top: 1px solid #ddd;
	}
}

ul#progress-bar {
	position: relative;
	display: block;
	width: 100%;
	max-width: 700px;
	margin: 3em auto 0 auto;
	text-align: center;
	
	li {
		position: relative;
		display: inline-block;
		list-style-type: none;
		width: 33%;
		
		a {
			&:link, &:visited, &:hover, &:active {
				position: relative;
				display: block;
				width: 100%;
				padding-top: 2.5em;
				text-align: center;
				text-decoration: none;
				color: $color-base-text;
				
				span.title {
					position: absolute;
					width: 100%;
					top: 0;
					left: 0;
					font-size: 1.2em;
					font-weight: 300;
				}
				
				span.deco {
					position: relative;
					display: inline-block;
					width: 25px;
					height: 25px;
					border-radius: 50%;
					background-color: #CCC;
					z-index: 1;
				}
				
				&[href="#"] {
					cursor: default;
				}
			}
		}
		
		// grey progress bar background line
		&:not(:first-child):after {
			content: " ";
			display: block;
			position: absolute;
			bottom: 12px;
			left: -50%;
			width: 100%;
			border-bottom: 4px solid #CCC;
		}
		
		&.complete {
			a {
				&:link, &:visited, &:hover, &:active {
					span.deco {
						background-color: grey;
					}
				}
			}
			
			// blue progress bar background line
			& + .complete {
				&:after {
					content: " ";
					display: block;
					position: absolute;
					bottom: 12px;
					left: -50%;
					width: 100%;
					border-bottom: 4px solid grey;
				}
			}
		}
	}
}