/* Variables
-------------------------------------*/
//colors
@import "./base/_colors.scss";

//fonts
@import './base/_font-faces.scss';
@import "./base/_fonts.scss";


@import "./bootstrap/bootstrap.scss";

@import './base/generic_classes';

@import "./elements/elements";



/* Imports
-------------------------------------*/

@import './elements/_datePicker.scss';
@import './lib/_libs.scss';

@media screen, projection {	
	@import '_main.scss'; // The main stylesheet
	@import 'shop'; // The shop stylesheet
	@import '_handheld.scss'; // The mobile/handheld stylesheet
	@import '_desktop.scss'; // The desktop stylesheet
}
@media print {	
	@import '_print.scss'; // Print styles
}
