.no-padding {
	padding-left:0;
	padding-right:0;
}

.padding-30 {
	padding: 30px 30px 40px 30px;
}

.grey-bg-lighter {
	background-color: $grey-bg-lighter;
}

.height-100 {
	height: 100%;
}

.black-link {
	&:link,
	&:visited,
	&:active,
	&:visited {
		color: $color-base-black;
		text-decoration: none;
		background-color: transparent;
		outline: none; 
		
	} 
}

big {
	display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 1px 10px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #212529;
    background-color: $yellow-link;
	border-color: $yellow-link;
	cursor: pointer;
	a {
		&:link,
		&:visited,
		&:active,
		&:visited {
			color: $color-base-black;
			text-decoration: none;
			background-color: transparent;
			outline: none;
		}
	}	
}

.more-link {
	position: absolute;
	bottom:0;
	right: 22px;
	padding: 0 7px 0 15px;
	transform: translateY(45%);
	line-height: 25px;
	display: flex;
	justify-content: center;
	height: 25px;
	
	&:link,
    &:visited,
    &:active,
    &:visited {
        color: $color-base-black;
        text-decoration: none;
        background-color: transparent;
        outline: none;
	}
	
	.fa {
		margin-left: 10px;
		font-size: 25px;
	}

	&.grey {background-color: $grey-link;}
	&.yellow {background-color: $yellow-link;}

	&.back {
		padding: 0 15px 0 7px;
		left: 22px;
		right: auto;

		.fa {
			margin-left: 0px;
			margin-right: 10px;
		}
	}

}