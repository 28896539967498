.arctur-vertical-align-container {
	display: table;
	height: 100%;
	width: 100%;
	> .cell {
		display: table-cell;
		&.v-top {
			vertical-align: top;
		}
		&.v-middle {
			vertical-align: middle;
		}
		&.v-bottom {
			vertical-align: bottom;
		}
		&.h-left {
			text-align: left;
		}
		&.h-center {
			text-align: center;
		}
		&.h-right {
			text-align: right;
		}
	}
}
.arctur-vertical-align{
	display: inline-block;
}
