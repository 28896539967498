// Import fonts
//@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT:400,700|Open+Sans:300,400,600|Raleway:300,400,600');
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&subset=latin-ext');

// Common font families
$font-family-headers: 'RalewayMedium', sans-serif; 
$font-family-headers-light: 'RalewayLight', sans-serif;
$font-family-headers-extralight: 'RalewayExtraLight', sans-serif;
$font-family-content: 'Source Sans Pro', sans-serif;
$font-family-print: 'Source Sans Pro', sans-serif;


// Common font sizes
$font-size-html: 16px; //rem default size
$font-size-content: 1rem;
$font-size-content-minus: 0.8rem;
$font-size-content-plus: 1.2rem;

$font-size-heading-1: 2.25rem;
$font-size-heading-2: 1.5rem;
$font-size-heading-3: 1.25rem;

$font-size-sidebar-headers: 1.8rem;

//bussiness card
$font-size-bussinesscard: 1.2rem; 

// --- navigation --- //
$font-size-navbar: 1.2rem;

/*
8px = 0.5rem
9px = 0.5625rem
10px = 0.625rem
11px = 0.6875rem
12px = 0.75rem
13px = 0.8125rem
14px = 0.875rem
15px = 0.9375rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
22px = 1.375rem
24px = 1.5rem
26px = 1.625rem
28px = 1.75rem
30px = 1.875rem
32px = 2rem
34px = 2.125rem
36px = 2.25rem
38px = 2.375rem
40px = 2.5rem
70px = 4.375rem
*/ 