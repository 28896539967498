/* Basic Elements
-------------------------------------*/

html { 
	height: 100%;
	font-size: $font-size-html;
 }

/* Generic Classes
-------------------------------------*/


body {
	font-family: $font-family-content;
	font-size: $font-size-content;
	color: $color-base-text;
	background-color: $color-base-bg;	
	line-height: 1.2rem;
	

	/* --- Common --- */

	//header section
	@import "./sections/_header.scss";

	//footer section
	@import "./sections/_footer.scss";

	//layout elements for both content / entry
	@import "./base/_layoutstyles.scss";
	
}

//ENTRY PAGE
@import "./sections/_entrypage.scss";

//CONTENT PAGE
@import "./sections/_contentpage.scss";


//sharing
@import "./sections/_sharing.scss";

//navigation 
@import "./sections/_navigation.scss";

// navigation - legacy uncomment if needed (be brave, be very brave)
//@import "./sections/_navigation-legacy.scss";

//sidebar
@import "./sections/_sidebar_module.scss";

