/* ---- header section ---- */

header {
    position: relative; 
    width: 100%;
    
    

    .header-wrapper-all {
        display: flex;
        flex-wrap: nowrap;
        

        .header-wrapper {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;       
        }

        .header-wrapper-img {            
            flex-grow: 1;
            display: flex; 
            flex-direction: column;  
            justify-content:flex-end;
            flex-grow: 1;
            flex-shrink: 1; 
            background-color:$grey-bg; 
            font-family:$font-family-headers;    

            .main-navigation {
                display: flex;
                padding: 15px 0;
                
          
            }

            @media screen and (min-width: map-get($container-max-widths, 'lg')) { 
                .navbar-expand-lg .navbar-nav {
                    flex-direction: column;
                }
            }             
        }
    }

    ul {
        li {
           &:before {
               display: none;
           } 
        }
    }

    #main-menu-bootstrap {
        &.navbar-nav {
            .nav-link {
                text-transform: uppercase;  
                padding-top: 0;
                padding-bottom: 0;
                color: $color-base-black;
                font-size: $font-size-navbar;
                line-height: 33px;

            }

            .bs-level1 {
                position: relative;
                margin-bottom: 0.6px;
                &:hover {
                    >.menu-bg {
                        background-color: rgba($yellow-link,.3);
                        position: absolute;
                        height: 100%;
                        width: calc(100% + 45px);
                        transform: translateX(-45px); 
                    }
                }         
            }

            .bs-level1-open {
                position: relative;
                >.menu-bg {
                    background-color: rgba($yellow-link,.5);
                    position: absolute;
                    height: 100%;
                    width: calc(100% + 45px);
                    transform: translateX(-45px); 
                }
            }
        }
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'lg')) {

        .header-wrapper-all {
            .header-wrapper-img {
                background-color: $color-base-white;

                .main-navigation {
                    padding: 0 0 15px 0;
                    .navbar-brand {
                        padding-top: 0;
                        .navbar-img {
                            max-width: 270px;
                        }
                    }

                    .navbar-toggler {
                        margin-right: 15px;
                        background-color: $yellow-bg;
                        border-radius: 0;
                        border-color:$yellow-bg;
                    }
                }
            }
        }



    }

    .header-banners {
        position: absolute;
        bottom: -18px;
        left:0;
        .hb-inner {
            display: inline-block;
            width: 30%;

            img {
                filter: grayscale(100%);
                opacity: .6;

                &:hover {
                    filter: grayscale(0);
                    opacity: 1;
                }
            }

        }
        p {
            line-height: 10px;
            margin-bottom: 0;
            small {
                color: $grey-link; 
                font-size: 70%;
            }
        }

    }

    
    /* Search
    -------------------------------------*/

    
    #search-mini-form {
        position: relative;
        display: flex;

        input.input-submit {
            margin-left: 4px;

        }
        #autocomplete-wrapper{
            position: absolute;
            top: 100%;
            width: 100%;
        }
    }

    #input-search-mini {
        padding: .3em;
        font-size: 100%;
        width: 132px;
    }

}