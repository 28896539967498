/* --- Other --- */
img {
	&.left {
		float: left;
		padding-left: 0;
		padding-right: 1rem;
	}

	&.right {
		float: right;
		padding-right: 0;
		padding-left: 1rem;
	}
}