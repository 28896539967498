/* --- News --- */

#news {
	.news-list {
		font-size: 1.15rem;
	}

	.link-list {
		font-size: 1.15rem;
	}
}


/* --- News list --- */

.news-list {
	li {
		margin-bottom: 1rem;
		list-style-type: none !important;
		
		a {
			&:link, &:visited, &:hover, &:active, &:visited {}
		}
		
		span {
			&.title {
				display: inline-block;
			}

			&.date {
				padding-right: 0.5em;
			}
			
			// Break the spans into newlines
			&:after {
				content: "\A";
				white-space: pre;
			}
		}
		
		.category {
			font-style: italic;
		}
	}
}

/* --- News content --- */

.news-content-wrapper {
	.date, .category {
		padding-bottom: 0;

		em {
			font-style: normal;
			font-weight: bold;
		}
	}

	.abstract {
		padding-top: .5em;
		font-style: italic;
		color: #555;
		display: block;
	}
}