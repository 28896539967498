/* --- calendar --- */

#calendar-table {
	border-collapse: collapse;

	th {
		color: #333;
		font-weight: normal;
		text-transform: uppercase;
	}

	td {
		width: 2.2em;
		padding: .6em 0 .8em 0;
		border: .1em solid white;
		text-align: center;
		line-height: 0;
		background-color: white;
		color: #333;
		cursor: default;

		&.week-selector {
			border: none;
			background-color: transparent;
		}
	}

	.day-existing {
		background-color: #eee;
	}

	.day-empty {
		background-color: white;
	}

	.day-existing, .day-empty {
		padding: 1.2em 0 0.9em 0;
	}

	td.day-event {
		background-color: #808080;
	}

	.day-event.day-today {
		background-color: #B90000;
		padding: 0;
	}

	.day-today span {
		display: block;
		color: white;
	}

	.day-selected {
		background-color: #808080;
		color: white;
	}

	.single-event {
		/* padding: 0; */
	}

	a {
		&:link, &:visited, &:hover, &:active {
			padding: .5em .5em .3em .5em;
			text-decoration: none;
			color: white;
		}
	}
}

#calendar-month-switcher {
	font-size: 1.15em;
	margin-top: .5em;
	display: block;
	
	a {
		&#cal-prev-month {
			&:link, &:visited, &:hover, &:active {}
		}

		&#cal-next-month {
			&:link, &:visited, &:hover, &:active {}
		}
	}
}