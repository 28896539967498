/* --- sharing --- */

#sharing {
	width: 100%;
	float: left;

	li {
		margin-top: 6px;
		width: 49.5%;
		float: left;
		font-size: 1.15rem;
	}
}

#facebook {
	background: url(/images/social_media/facebook.png) no-repeat;
}

#twitter {
	background: url(/images/social_media/twitter.png) no-repeat;
}

#linkedin {
	background: url(/images/social_media/linkedin.png) no-repeat;
}

#delicious {
	background: url(/images/social_media/delicious.png) no-repeat;
}

#sharing a {
	&:link, &:visited, &:hover, &:active {
		padding-left: 22px;
	}
}