
table.jCalendar {
  /*background:#AAAAAA none repeat scroll 0% 0%;*/

  border-collapse: separate;
  border-spacing: 2px;

  th {
    background: $gray-100 none repeat scroll 0% 0%;
    color: $gray-700;
    //font-weight: bold;
    padding: 3px 5px;
    text-align: center;
    
  }

  td {
    background: #FFFFFF none repeat scroll 0% 0%;
    color: #000000;
    //font-weight: bold;
    padding: 3px 5px;
    text-align: center;
    font-size: 1rem;

    &.other-month {
      /*background: #ddd;*/
      color: $gray-500;
    }

    &.today {
      border: 1px solid $primary;
    }

    &.selected {
      background: $primary;
      color: white;

    }

    &:hover, &.dp-hover {
      background: $primary;
      color: white;
    }

    &.disabled {
      background: #bbb;
      color: $gray-500;

      &:hover {
        background: #bbb;
        color: #888;
      }
    }
  }
}

/* For the popup */

/* NOTE - you will probably want to style a.dp-choose-date - see how I did it in demo.css */

div {
  &.dp-popup {
    position: relative;
    background: #FFFFFF none repeat scroll 0% 0%;
    border: 1px solid $primary;
    font-family: $font-family-content;
    line-height: 1em;
    border-radius: $border-radius;
  }

  &#dp-popup {
    position: absolute;
    z-index: 199;
  }

  &.dp-popup h2 {
    font-size: 12px;
    text-align: center;
    margin: 0;
    padding: 5px 0;
    background-color: $primary;
    color: white;
    font-size: 1.25rem;
    font-family: $font-family-content;

  }
}

a#dp-close {
  font-size: 11px;
  padding: 4px 0;
  text-align: center;
  display: block;

  &:hover {
    text-decoration: underline;
  }
}

div.dp-popup {
  a {
    color: #000;
    text-decoration: none;
    padding: 6px 2px 0;
  }

  div {
    &.dp-nav-prev {
      position: absolute;
      top: 2px;
      left: 4px;
      width: 100px;
      font-size: 1.2rem;

      a {
        float: left;
        color: white;
        cursor: pointer;

      }
    }

    &.dp-nav-next a {
      cursor: pointer;
    }

    &.dp-nav-prev a.disabled {
      cursor: default;
    }

    &.dp-nav-next {
      font-size: 1.2rem;

      a {
        &.disabled {
          cursor: default;
        }

        float: right;
        color: white;
      }

      position: absolute;
      top: 2px;
      right: 4px;
      width: 100px;
    }
  }

  a.disabled {
    cursor: default;
    color: $gray-500;
  }

  td {
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }
}

/* Opera needs the rules to be this specific otherwise it doesn't change the cursor back to pointer after you have disabled and re-enabled a link */

