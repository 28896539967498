@media screen and (max-width: map-get($grid-breakpoints, 'xxl')) {
    body{
        //background:rgba( red,.2);
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'xl')) {
    body{
        //background:rgba(blue,.2);
        &#body-content {
            #photo-gallery-container {
                &.generic {
                    .grid-sizer, .grid-item { 
                        width: calc(33.333% - 10px); 
                    }
                }
        
        
            }
            .photo-list {
                .photo-list-item {
                    flex-basis: 49%;                    
                }
            }            
        }
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'lg')) {
    body{
        //background:rgba(green,.2);
        .right-title {
            text-align: left !important;
        }

        .about {
            margin-bottom: 0px  !important;
        }

        .training-wrapper-about, .mentorji {
            margin-left:0;
            margin-right:0;
        }        
        
        .ekipa {
            margin-left:0;
            margin-right:0;

            .photo-list-item {
                flex-wrap: wrap;
            }
            .photo-list-photo {
                flex-basis: 100%;
            }
        
            .photo-list-content {
                flex-basis: 100%;
                padding-top: 15px;
            }
        } 
        
        .cilji {
            .no-padding {
                padding-right: 15px;
                padding-left: 15px; 
            }
        }

        .header-wrapper-img {
            #bs-main-menu-collapse {
                padding-right: 15px;
                padding-left: 15px;
                padding-top: 25px; 
            }

        }

        header{
            .header-banners {
                position:relative;
                bottom: initial;
                margin-top: -15px;
                text-align: left;
                padding: 0 15px  5px 15px;
                //background-color: $grey-bg-lighter;
                img {
                    max-width: 80px;
                }

                .hb-inner {
                    width: auto;
                }
            }

        }
        
    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'md')) {
    body{
        //background: rgba(yellow,.2);

        .mentorji {
            .list {
        
                .ment-item {
                    flex-basis: 50%;
                }
            }
        }

        #gallery-item-1 {
            display: none !important;
        }

        &#body-content {
            .news-list {
                .news-item {
                    flex-wrap: wrap;
                    margin-bottom: 30px;
                    .background-image {
                        height: 220px;
                        width: 100%;
                        margin-right: 0;
                        flex-grow:1; 
                    }    
        
                    .text-wrapper {
                        flex-grow:1;
                        width: 100%;
                        padding: 30px;
                    }
                }
            }
            .photo-list {
                .photo-list-item {
                    flex-basis: 100%;                    
                }
            } 
            
            #photo-gallery-container {
                &.generic {
                    .grid-sizer, .grid-item { 
                        width: calc(50% - 10px); 
                    }
                }
        
        
            }  
            
            #banners-list-lead, #banners-list-other {
                .banners-data{
                    flex-direction: column;
                    .banner.img-fluid {
                        width: 130px;
                        margin-bottom: 10px;
                    }
                } 
            }
        }
        

    }
}

@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
    body{
        //background: white; 
        .mentorji {
            .list {
        
                .ment-item {
                    flex-basis: 100%;
                }
            }
        } 
        
        #gallery-item-2 {
            display: none !important;
        }

        &#body-content {
            #photo-gallery-container {
                &.generic {
                    .grid-sizer, .grid-item { 
                        width: 100%; 
                    }
                }
        
        
            }
        }
    }
}

@media screen and (max-width: 768px) {
    html{
        font-size: $font-size-html - 1;
    }
    //mobile responsivness
    nav{
        #main-menu{
            margin: 0px;
            li.menu-level1{
                display: block;
                ul.menu-level2{
                    position: relative;
                    width: 100%;
                    transform: none;
                }
            }
        }
    }
    body {
        //form date popup responsivness
        div#dp-popup {
            left: auto !important;
            right: 10px !important;
        }
        header {
            &.navbar-margin-bottom {
                margin-bottom: 90px;
            }
            //remove extra margin from search miniform btn
            #search-mini-form {
                input {
                    &.input-submit {
                        margin-left: 0px;
                    }
                }
            }
        }
        nav {
            &.navbar {
                &.navbar-top-collapse {
                    border:none;
                    position: fixed;
                    left: 0px;
                    right: 0px;
                    background-color: white;
                    z-index: 1;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    max-height: 100vh;
                    overflow: auto;
                    .navbar-top-collapse-header {
                        display: table;
                        .logo-wrapper, .btn-wrapper {
                            vertical-align: middle;
                        }
                        .logo-wrapper {
                            display: table-cell;
                            width: 100%;
                        }
                        .btn-wrapper {
                            display: table-cell;
                            text-align: center;
                        }
                    }
                    .navbar-collapse {
                        border: none;
                    }
                }
                &.navbar-side-collapse {
                    min-height: 0px;
                    border: none;
                }
                .side-navbar-img-wrapper {
                    margin: 20px 10px;
                }
               
                #bs-main-menu-collapse-side {
                    
                    top:0px;
                    background: white;
                    z-index: 99999;
                    bottom:0;
                    left:0;
                    width:300px;
                    position:fixed;
                    overflow:hidden;
                    transition:width .4s;
                    overflow-y: auto;
                    &.in {
                        width: 0;
                    }
                    &:not(.in) {
                        padding: 10px 25px;
        
                    }
                }
               
            }
        }
        .side-collapse-container {
            width:100%;
            position:relative;
            left:0;
            transition:left .4s;
            &.out {
                left:300px;
            }
        }
        .table-wrapper {
            .logo-wrapper {
                vertical-align: middle;
                width: 100%;
            }
            .hamburger-wrapper {
               vertical-align: middle;
            }
        }
        
    }
}
