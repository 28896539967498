/* Banners list styles
------------------------*/
.banners {
    #banners-list {
        margin-top: 100px;
        .banners-item{
            flex-basis:200px;
    
            img {
                width: auto;
                max-height: 80px;
                margin: 0 auto;
            }
    
            .banners-type {
                text-transform: uppercase;
                font-size: $font-size-content-minus;
                color: $grey-link;
            }
            .banners-title {
                text-transform: uppercase;
                font-size: $font-size-content-minus;
                color: $grey-link;
            }            
        }
    }

    

    .entry-statement {
        margin-top: 40px;
        //background-color: $yellow-bg;
        padding: 30px;        
        p {            
            font-size: $font-size-content-minus;
            margin-bottom: 0;
        }
    } 


}

#banners-list-lead, #banners-list-other {
    margin-bottom: 60px;

    .banners-data{
        background-color: rgba($color-base-white, .6);
        padding: 30px;
    }    

    .banners-item {
        margin-bottom: 15px;
    }

    .banners-img {
        flex-basis: 150px;
        margin-right: 30px;
    }

    .banners-title {
        flex-basis: calc(100% - 180px); 

        h2 {
            font-size: 90%;
            font-weight: 600;
        }
    }
}
